<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2>Платеж {{ data.id }}</h2>
                    </div>

                    <div class="d-flex" v-if="data.type.key == 'deposit'">
                        <div class="form-check" style="float:right">
                            <input 
                                v-model="data.is_fake"
                                class="form-check-input" 
                                id="is_fake" 
                                type="checkbox">
                            <label class="form-check-label" for="is_fake">Фейк</label>
                        </div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row g-5">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-6" :class="{'col-md-12': data.type.key == 'deposit', 'col-md-6': data.type.key == 'withdrawal'}">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.user}">
                                        <vue-select
                                            disabled
                                            v-model="data.user"
                                            placeholder="Пользователь"
                                            label="email">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователь</label>
                                        <div class="invalid-feedback">{{errors.user}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            disabled
                                            v-model="data.type"
                                            placeholder="Направление"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Направление</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.amount"
                                            @input="errors.amount = null"
                                            :class="{'is-invalid': errors.amount}"
                                            placeholder="Сумма"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Сумма</label>
                                        <div class="invalid-feedback">{{errors.amount}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6" v-if="data.type.key == 'withdrawal'">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wallet"
                                            @input="errors.wallet = null"
                                            :class="{'is-invalid': errors.wallet}"
                                            placeholder="Кошелек/Карта"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Кошелек/Карта</label>
                                        <div class="invalid-feedback">{{errors.wallet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.paysystem}">
                                        <vue-select
                                            disabled
                                            v-model="data.paysystem"
                                            placeholder="Платежная система"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Платежная система</label>
                                        <div class="invalid-feedback">{{errors.paysystem}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.paysystem_method}">
                                        <vue-select
                                            disabled
                                            v-model="data.paysystem_method"
                                            placeholder="Метод"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Метод</label>
                                        <div class="invalid-feedback">{{errors.paysystem_method}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            disabled
                                            :class="{'is-invalid': errors.date}"
                                            @update="errors.date = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :single-date-picker="pikerparams.single"
                                            :minDate="pikerparams.mindate"
                                            :ranges="pikerparams.ranges"
                                            :showDropdowns="pikerparams.dropdowns"
                                            :time-picker="pikerparams.timePicker"
                                            :time-picker24-hour="pikerparams.timePicker24Hour"
                                            v-model="data.date">
                                        </date-range-picker>

                                        <label>Дата</label>
                                        <div class="invalid-feedback">{{errors.date}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            item: {},
            data: {
                id: null,
                user: null,
                type: {
                    name: 'Депозит',
                    key: 'deposit'
                },
                amount: null,
                wallet: null,
                paysystem: null,
                paysystem_method: null,
                date: {
                    startDate: this.$moment().set({day: this.$moment().get('day')}).format('Y-MM-DD HH:mm:ss')
                },
                status: {
                    name: 'Выполнен',
                    key: 1
                },
                is_fake: 1
            },
            errors: {
                user: false,
                type: false,
                amount: false,
                wallet: false,
                paysystem: false,
                paysystem_method: false,
                date: false,
                status: false
            },
            utils: {
                users: [],
                paysystems: [],
                paysystem_methods: [],
                types: [
                    {
                        name: 'Депозит',
                        key: 'deposit'
                    },
                    {
                        name: 'Выплата',
                        key: 'withdrawal'
                    }
                ],
                statuses: [
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Обрабатывается',
                        key: 4
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    },
                    {
                        name: 'Отменен',
                        key: 3
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            }
        }
    },
    methods: {
        async getItem () {
            await this.$axios.post(`/payments/item/${this.$props.id}`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        this.item = response.data

                        this.data = {
                            id: response.data.id,
                            user: response.data.user_id,
                            type: this.utils.types.find(row => row.key == response.data.type),
                            amount: response.data.amount,
                            wallet: response.data.wallet,
                            paysystem: response.data.paysystem_id,
                            paysystem_method: null,
                            date: {
                                startDate: this.$moment().set({day: this.$moment(response.data.created_at).get('day')}).format('Y-MM-DD HH:mm:ss')
                            },
                            status: this.utils.statuses.find(row => row.key == response.data.status),
                            is_fake: response.data.is_fake,
                            wallet: response.data.type == 'withdrawal' ? response.data.data.wallet : null
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'MerchantsItems'})
                }
            })
        },
        updateItem () {
            if(!this.data.amount) {
                this.errors.amount = 'Это поле обязательно'
                return
            }
            if(this.data.type.key == 'withdrawal') {
                if(!this.data.wallet) {
                    this.errors.wallet = 'Это поле обязательно'
                    return
                }
            }
            if(!this.data.date) {
                this.errors.date = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true
            this.$axios.post('/payments/update', JSON.stringify({
                id: this.data.id,
                amount: this.data.amount,
                wallet: this.data.type.key == 'withdrawal' ? this.data.wallet : '',
                date: this.$moment(this.data.date.startDate).format('Y-MM-DD HH:mm:ss'),
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
        },
        searchUsers (ids) {
            this.$axios.post('/users/search/ids', JSON.stringify({
                users: ids
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        this.data.user = {
                            id: response.data[0].id,
                            email: response.data[0].email
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        getPaysystems () {
            if (!this.data.user || !this.data.type) {
                return
            }

            this.data.paysystem_method = null

            this.$axios.post('/paysystems/items', JSON.stringify({
                pager: {
                    per_page: 1000,
                    page: 0
                },
                sort: {
                    by: null,
                    dir: null
                },
                search: {
                    paysys_id: this.data.paysystem,
                    paysys: null,
                    merchant: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        this.data.paysystem = response.data.items.find(row => row.id == this.data.paysystem)
                        this.data.paysystem_method = this.data.paysystem.methods.find(row => row.slug == this.item.data.method)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        setPaysystem () {
            this.utils.paysystem_methods = []
            for (let item of this.data.paysystem.methods) {
                this.utils.paysystem_methods.push({
                    name: item.name,
                    key: item.slug
                })
            }
        }
    },
    async beforeMount () {
        await this.getItem()
        this.searchUsers([this.data.user])
        this.getPaysystems()

        this.preloader = false
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
