<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                
                <div class="row g-3">
                    <div class="col-sm-6 col-md-6">
                        <h2 class="text-bold text-1100 mb-5">
                            Аналитика
                        </h2>
                    </div>
                </div>

                <div class="row align-items-center justify-content-between g-3 mb-3" v-show="!preloader">
                    
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container" id="merchant_block">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.merchant"
                                :options="utils.merchants" 
                                placeholder="Мерчант"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container" id="type_payment_block">
                            <vue-select
                                @input="changePaysystem(), changeGroup()"
                                v-model="search.type"
                                :options="utils.types" 
                                placeholder="Тип платежа"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container" id="group_by_block">
                            <vue-select
                                @input="changeGroup"
                                v-model="search.group_by"
                                :options="utils.group_by" 
                                placeholder="Группировка по"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.status"
                                :options="utils.statuses" 
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.paysystem"
                                :options="utils.paysystems_filtered" 
                                placeholder="Платежная система"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchUsers"
                                v-model="search.user"
                                :options="utils.users" 
                                placeholder="Пользователь"
                                label="search">
                                <template v-slot:option="option">
                                    <span>{{ option.email }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small fs--1">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <template v-if="row.is_total">
                                            <td>{{ row.total }}</td>
                                        </template>
                                        <template v-else>
                                            <td v-if="search.group_by && search.group_by.key == 'user_id'">
                                                <router-link :to="{name: 'UsersItem', params: {id: row.user_id}}">{{ row.user_email }}</router-link>
                                            </td>
                                            <td v-if="search.group_by && search.group_by.key == 'paysystem'">
                                                {{ row.paysystem_name }} / {{ capitalizeFirstLetter(row.paysystem_method) }}
                                            </td>
                                            <td v-if="!search.group_by || search.group_by.key == 'date'">
                                                {{ $moment(row.date).format('YYYY-MM-DD') }}
                                            </td>
                                        </template>
                                        <td>{{ parseFloat(row.amount).toFixed(2) }}</td>
                                        <td>{{ parseFloat(row.amount_without_paysys_comission).toFixed(2) }}</td>
                                        <td>{{ row.count }}</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>

export default {
    data () {
        return {
            preloader: true,
            pager: {
                per_page: 1000,
                page: 0
            },
            search: {
                search_change: null,
                user: null,
                merchant: {
                    name: 'Rubbles',
                    currency: 'RUB',
                    id: 1
                },
                type: {
                    name: 'Депозиты',
                    key: 'deposit'
                },
                status: {
                    name: 'Выполнен',
                    key: 1
                },
                paysystem: null,
                group_by: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            columns: [
                {label: 'Дата', field: 'date', sortable: true},
                {label: 'Сумма депозитов', field: 'amount', sortable: true},
                {label: 'Чистая сумма', field: 'amount_without_paysys_comission', sortable: true},
                {label: 'Всего депозиты', field: 'count', sortable: true},
            ],
            utils: {
                users: [],
                merchants: [],
                paysystems: [],
                paysystems_filtered: [],
                types: [
                    {
                        name: 'Депозиты',
                        key: 'deposit'
                    },
                    {
                        name: 'Выплаты',
                        key: 'withdrawal'
                    }
                ],
                group_by: [
                    {
                        name: 'Пользователю',
                        key: 'user_id'
                    },
                    {
                        name: 'Платежной системе',
                        key: 'paysystem'
                    },
                    {
                        name: 'Дате',
                        key: 'date'
                    }
                ],
                statuses: [
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    },
                    {
                        name: 'Отменен',
                        key: 3
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/payments/analytics/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user: this.search.user ? this.search.user.id : null,
                    merchant: this.search.merchant.id,
                    type: this.search.type.key,
                    status: this.search.status ? this.search.status.key : null,
                    paysystem: this.search.paysystem ? this.search.paysystem.id : null,
                    group_by: this.search.group_by ? this.search.group_by.key : null,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        end: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            items.data.items.push({
                total: 'Всего',
                amount: this.sumFields(items.data.items, 'amount'),
                amount_without_paysys_comission: this.sumFields(items.data.items, 'amount_without_paysys_comission'),
                count: this.sumFields(items.data.items, 'count'),
                is_total: true
            })

            if(items && items.status == 'success') {   
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getPaysystems () {
            this.$axios.post('/paysystems/items', JSON.stringify({
                pager: {
                    per_page: 1000,
                    page: 0
                },
                sort: {
                    by: null,
                    dir: null
                },
                search: {
                    paysys: null,
                    type: null,
                    merchant: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data.items) {
                            this.utils.paysystems.push({
                                name: item.name,
                                type: item.type,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        this.changePaysystem()
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                console.log(error)
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email,
                                    search: search
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        },
        changeGroup () {
            var group_by = null
            if(!this.search.group_by) {
                group_by = 'date'
            } else {
                group_by = this.search.group_by.key
            }
            if(['user_id', 'paysystem', 'date'].includes(group_by)) {
                if(group_by == 'user_id') {
                    this.columns = [
                        {label: 'Пользователь'},
                        {label: (this.search.type.key == 'deposit' ? 'Сумма депозитов' : 'Сумма выплат'), field: 'amount', sortable: true},
                        {label: (this.search.type.key == 'deposit' ? 'Всего депозитов' : 'Всего выплат'), field: 'count', sortable: true},
                    ]
                } else if(group_by == 'paysystem') {
                    this.columns = [
                        {label: 'Платежная система'},
                        {label: (this.search.type.key == 'deposit' ? 'Сумма депозитов' : 'Сумма выплат'), field: 'amount', sortable: true},
                        {label: (this.search.type.key == 'deposit' ? 'Всего депозитов' : 'Всего выплат'), field: 'count', sortable: true},
                    ]
                } else if(group_by == 'date') {
                    this.columns = [
                        {label: 'Дата', field: 'date', sortable: true},
                        {label: (this.search.type.key == 'deposit' ? 'Сумма депозитов' : 'Сумма выплат'), field: 'amount', sortable: true},
                        {label: (this.search.type.key == 'deposit' ? 'Всего депозитов' : 'Всего выплат'), field: 'count', sortable: true}
                    ]
                }
            }
        },
        changePaysystem() {
            this.utils.paysystems_filtered = []
            for (let item of this.utils.paysystems) {
                if(item.type == this.search.type.key) {
                    this.utils.paysystems_filtered.push({
                        name: item.name,
                        type: item.type,
                        id: item.id
                    })
                }
            }
        },
        capitalizeFirstLetter(str) {
            if(str) {
                return str[0].toUpperCase() + str.slice(1)
            }
        },
        sumFields (data, key) {
            return data.reduce((accumulator, object) => {
                return accumulator + parseFloat(object[key])
            }, 0)
        }
    },
    beforeMount() {
        this.getMerchants()
        this.getPaysystems()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep #merchant_block .vs__clear {
        display: none;
    }

    ::v-deep #group_by_block .vs__clear {
        display: none;
    }

    ::v-deep #type_payment_block .vs__clear {
        display: none;
    }
</style>
