<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>


            <div class="content">

                <div class="row g-3">
                    <div class="col-sm-6 col-md-6">
                        <h2 class="text-bold text-1100 mb-5">Статусы пользователей</h2>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-if="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :columns="columns" 
                                :data="statuses">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.name }}</td>
                                        <td>{{ row.cashback }}</td>
                                        <td>
                                            <router-link
                                                :to="{name: 'UsersStatusItem', params: {id: row.edit_id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            statuses: [],
            columns: [
                {label: 'Название'},
                {label: 'Cashback (%)'},
                {label: ''},
            ]
        }
    },
    methods: {
        getItems () {
            this.$axios.post('/users/status/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.statuses = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        }
    },
    beforeMount() {
        this.getItems()
    }
}
</script>
