<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2>Создать Платеж</h2>
                    </div>

                    <div class="d-flex" v-if="data.type.key == 'deposit'">
                        <div class="form-check" style="float:right">
                            <input 
                                v-model="data.is_fake"
                                class="form-check-input" 
                                id="is_fake" 
                                type="checkbox">
                            <label class="form-check-label" for="is_fake">Фейк</label>
                        </div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row g-5">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-6" :class="{'col-md-12': data.type.key == 'deposit', 'col-md-6': data.type.key == 'withdrawal'}">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.user}">
                                        <vue-select
                                            @input="errors.user = null, getPaysystems()"
                                            @search="searchUsers"
                                            v-model="data.user"
                                            :options="utils.users" 
                                            placeholder="Пользователь"
                                            label="search">
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching && search.length >= 3">
                                                    Ничего не найдено по запросу <em>{{ search }}</em>.
                                                </template>
                                                <span v-else>Введите не менее 3 символов</span>
                                            </template>
                                        </vue-select>
                                        <label>Пользователь</label>
                                        <div class="invalid-feedback">{{errors.user}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.type = null, getPaysystems()"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Направление"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Направление</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.amount"
                                            @input="errors.amount = null"
                                            :class="{'is-invalid': errors.amount}"
                                            placeholder="Сумма"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Сумма</label>
                                        <div class="invalid-feedback">{{errors.amount}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6" v-if="data.type.key == 'withdrawal'">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wallet"
                                            @input="errors.wallet = null"
                                            :class="{'is-invalid': errors.wallet}"
                                            placeholder="Кошелек/Карта"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Кошелек/Карта</label>
                                        <div class="invalid-feedback">{{errors.wallet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.paysystem}">
                                        <vue-select
                                            :disabled="!data.user || !data.type"
                                            @input="errors.paysystem = null, setPaysystem()"
                                            v-model="data.paysystem"
                                            :options="utils.paysystems" 
                                            placeholder="Платежная система"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Платежная система</label>
                                        <div class="invalid-feedback">{{errors.paysystem}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.paysystem_method}">
                                        <vue-select
                                            :disabled="!data.paysystem"
                                            @input="errors.paysystem_method = null"
                                            v-model="data.paysystem_method"
                                            :options="utils.paysystem_methods" 
                                            placeholder="Метод"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Метод</label>
                                        <div class="invalid-feedback">{{errors.paysystem_method}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            :class="{'is-invalid': errors.date}"
                                            @update="errors.date = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :single-date-picker="pikerparams.single"
                                            :minDate="pikerparams.mindate"
                                            :ranges="pikerparams.ranges"
                                            :showDropdowns="pikerparams.dropdowns"
                                            :time-picker="pikerparams.timePicker"
                                            :time-picker24-hour="pikerparams.timePicker24Hour"
                                            v-model="data.date">
                                        </date-range-picker>

                                        <label>Дата</label>
                                        <div class="invalid-feedback">{{errors.date}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Создать</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            data: {
                user: null,
                type: {
                    name: 'Депозит',
                    key: 'deposit'
                },
                amount: null,
                wallet: null,
                paysystem: null,
                paysystem_method: null,
                date: {
                    startDate: this.$moment().set({day: this.$moment().get('day')}).format('Y-MM-DD HH:mm:ss')
                },
                status: {
                    name: 'Выполнен',
                    key: 1
                },
                is_fake: 1
            },
            errors: {
                user: false,
                type: false,
                amount: false,
                wallet: false,
                paysystem: false,
                paysystem_method: false,
                date: false,
                status: false
            },
            utils: {
                users: [],
                paysystems: [],
                paysystem_methods: [],
                types: [
                    {
                        name: 'Депозит',
                        key: 'deposit'
                    },
                    {
                        name: 'Выплата',
                        key: 'withdrawal'
                    }
                ],
                statuses: [
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Обрабатывается',
                        key: 4
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    },
                    {
                        name: 'Отменен',
                        key: 3
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.user) {
                this.errors.user = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(!this.data.amount) {
                this.errors.amount = 'Это поле обязательно'
                return
            }
            if(this.data.type.key == 'withdrawal') {
                if(!this.data.wallet) {
                    this.errors.wallet = 'Это поле обязательно'
                    return
                }
            }
            if(!this.data.paysystem) {
                this.errors.paysystem = 'Это поле обязательно'
                return
            }
            if(!this.data.paysystem_method) {
                this.errors.paysystem_method = 'Это поле обязательно'
                return
            }
            if(!this.data.date) {
                this.errors.date = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true
            this.$axios.post('/payments/create', JSON.stringify({
                user: this.data.user.id,
                type: this.data.type.key,
                amount: this.data.amount,
                wallet: this.data.type.key == 'withdrawal' ? this.data.wallet : '',
                paysystem: this.data.paysystem.id,
                paysystem_method: this.data.paysystem_method.key,
                date: this.$moment(this.data.date.startDate).format('Y-MM-DD HH:mm:ss'),
                status: this.data.status.key,
                is_fake: this.data.is_fake
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'PaymentsItems', params: {type: this.data.type.key}})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email,
                                    merchant_id: user.merchant_id,
                                    search: search
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        getPaysystems () {
            if (!this.data.user || !this.data.type) {
                return
            }

            this.utils.paysystems = []
            this.utils.paysystem_methods = []
            this.data.paysystem = null
            this.data.paysystem_method = null

            this.$axios.post('/paysystems/items', JSON.stringify({
                pager: {
                    per_page: 1000,
                    page: 0
                },
                sort: {
                    by: null,
                    dir: null
                },
                search: {
                    paysys: null,
                    type: this.data.type.key,
                    merchant: this.data.user.merchant_id
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        
                        for (let item of response.data.items) {
                            this.utils.paysystems.push({
                                name: item.name,
                                type: item.type,
                                methods: item.methods,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        setPaysystem () {
            this.utils.paysystem_methods = []
            for (let item of this.data.paysystem.methods) {
                this.utils.paysystem_methods.push({
                    name: item.name,
                    key: item.slug
                })
            }
        }
    },
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
